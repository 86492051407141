/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Layout from '../components/layout/layout';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { SiteMapHero, Fonts, SiteMapContent } from '../global.css';
import BackToTop from '../components/back-to-top/back-to-top';
import Modal from '../components/modal/modal';
import ContactUsHeader from '../components/contact-us-header/contact-us-header';

class SiteMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExternalLinkModal: '',
      modalOpen: true,
    };
    this.hideModal = this.hideModal.bind(this);
  }
  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }
  handleClick(event) {
    if (
      event &&
      event.target &&
      event.target.href &&
      event.target.href.indexOf('contact-us-modal') > -1
    ) {
      event.preventDefault();
      event.stopPropagation();
      let href = event.target.href;
      if (this.state.showExternalLinkModal.length) {
        this.setState({
          showExternalLinkModal: '',
        });
      }
      setTimeout(() => {
        this.setState({
          showExternalLinkModal: href,
          modalOpen: true,
        });
      }, 100);
    }
  }
  render() {
    let { data } = this.props;
    return (
      <Layout>
        <Fonts>
          <SiteMapHero>
            <div>
              <h1>{data.siteMapJson.title}</h1>
              <p>{data.siteMapJson.heroContent}</p>
            </div>
          </SiteMapHero>
          <SiteMapContent>
            <div
              onClick={event => this.handleClick(event)}
              dangerouslySetInnerHTML={{
                __html: data.siteMapJson.pageContent.childMarkdownRemark.html,
              }}
            />
          </SiteMapContent>
        </Fonts>
        <BackToTop />
        {this.state.showExternalLinkModal &&
        this.state.showExternalLinkModal.length ? (
          <Modal
            autoOpen={true}
            open={this.state.modalOpen}
            type={'redirect'}
            hideModal={this.hideModal}
          >
            <ContactUsHeader
              hideModal={this.hideModal}
              url={this.state.showExternalLinkModal}
            />
          </Modal>
        ) : (
          undefined
        )}
      </Layout>
    );
  }
}

export default SiteMap;

SiteMap.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    siteMapJson {
      title
      heroContent
      pageContent {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
